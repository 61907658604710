<template>
  <mobile-screen :header="true" screen-class="resources-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("services.service-items.add-service-item") }}
        </div>
        <template v-slot:right>
          <button
            @click="initSubmit"
            v-if="
              $route.name == 'r_add-service-item' &&
                can('MANAGE_SERVICE_ITEMS', 'create')
            "
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      :key="formKey"
      ref="addServiceItemForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.has_translations }"
        >
          <div class="error-message" v-if="errors.has_translations">
            {{ errors.has_translations }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("services.service-items.name") }}
              </div>
              <p class="text-label">{{ displayServiceItemName }}</p>
            </div>
            <span class="follow-up-icons">
              <router-link
                :to="{
                  name: 'r_add-service-item-translate-name',
                  params: $route.params
                }"
                class="follow-up-icon-item"
              >
                <icon icon="#cx-men1-translations" />
              </router-link>
            </span>
          </div>
          <Field
            name="has_translations"
            as="input"
            type="hidden"
            rules="required"
            v-model="hasNameTranslations"
          />
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.level }"
          style="cursor: pointer;"
        >
          <router-link
            :to="{
              name: 'r_add-service-item-type',
              params: $route.params
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
              >
                {{ displayLabelName("services.service-items.type") }}
              </label>
              <p v-if="typeName" class="text-label" style="cursor: pointer;">
                {{ typeName }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                  style="cursor: pointer;"
                />
              </span>
            </span>
          </router-link>
          <Field name="typeId" as="input" type="hidden" v-model="typeId" />
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="hasQuantity"
                    type="checkbox"
                    v-model="hasQuantity"
                  />
                  <label for="hasQuantity"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("services.service-items.has-quantity") }}
              </dd>
            </dl>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="code">{{
                displayLabelName("services.service-items.code")
              }}</label>
              <Field id="code" name="code" as="input" type="text" />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.has_translations }"
        >
          <div class="error-message" v-if="errors.has_translations">
            {{ errors.has_translations }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("services.service-items.description") }}
              </div>
              <p class="text-label">{{ displayServiceItemDescription }}</p>
            </div>
            <span class="follow-up-icons">
              <router-link
                :to="{
                  name: 'r_add-service-item-translate-description',
                  params: $route.params
                }"
                class="follow-up-icon-item"
              >
                <icon icon="#cx-men1-translations" />
              </router-link>
            </span>
          </div>
          <Field
            name="has_translations"
            as="input"
            type="hidden"
            rules="required"
            v-model="hasTranslations"
          />
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="price">{{
                displayLabelName("services.service-items.price")
              }}</label>
              <Field id="price" name="price" as="input" type="text" />
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="setup">{{
                displayLabelName("services.service-items.setup")
              }}</label>
              <div style="display: flex; flex-direction: row;">
                <span
                  class="change-time"
                  @click="decreaseTime('setup')"
                  style="margin-left: 15px;"
                  >-</span
                >
                <Field
                  id="setup"
                  name="setup"
                  as="input"
                  type="text"
                  disabled="true"
                  v-model="setup"
                  style="width: 70px; text-align: center;"
                />
                <span class="change-time" @click="increaseTime('setup')"
                  >+</span
                >
              </div>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="cleanup">{{
                displayLabelName("services.service-items.cleanup")
              }}</label>
              <div style="display: flex; flex-direction: row;">
                <span
                  class="change-time"
                  @click="decreaseTime('cleanup')"
                  style="margin-left: 15px;"
                  >-</span
                >
                <Field
                  id="cleanup"
                  name="cleanup"
                  as="input"
                  type="text"
                  disabled="true"
                  v-model="cleanup"
                  style="width: 70px; text-align: center;"
                />
                <span class="change-time" @click="increaseTime('cleanup')"
                  >+</span
                >
              </div>
            </div>
          </div>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import httpServiceAuth, { getLang } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "AddServiceItem",
  data() {
    return {
      formKey: 0,
      isSearchable: false,
      cleanup: 0,
      setup: 0
    };
  },
  created() {
    this.setTranslations();
  },
  computed: {
    ...mapState("service", [
      "serviceItemTranslations",
      "serviceItemTranslations",
      "serviceType"
    ]),
    ...mapState("language", ["languages"]),
    hasNameTranslations() {
      const { languages, serviceItemTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.length === serviceItemTranslations.length;
      }
      return false;
    },
    hasDescriptionTranslations() {
      const { languages, serviceItemTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.length === serviceItemTranslations.length;
      }
      return false;
    },
    displayServiceItemName() {
      const { serviceItemTranslations } = this;
      const locale = getLang();
      if (serviceItemTranslations && serviceItemTranslations.length) {
        const filtered = serviceItemTranslations.filter(
          item => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    displayServiceItemDescription() {
      const { serviceItemTranslations } = this;
      const locale = getLang();
      if (serviceItemTranslations && serviceItemTranslations.length) {
        const filtered = serviceItemTranslations.filter(
          item => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.description;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    typeId() {
      if (this.serviceType) {
        return this.serviceType.id;
      }
      return null;
    },
    typeName() {
      if (this.serviceType) {
        return this.serviceType.name;
      }
      return null;
    }
  },
  watch: {
    languages() {
      this.setTranslations();
    }
  },
  methods: {
    ...mapActions("service", ["getServiceItems", "setServiceItemTranslations"]),
    decreaseTime(type) {
      if (this.serviceItem && this.serviceItem.data) {
        if (type == "setup") {
          if (this.serviceItem.data.setup_time > 0) {
            this.serviceItem.data.setup_time =
              this.serviceItem.data.setup_time - 15;
          }
        } else {
          if (this.serviceItem.data.cleanup_time > 0) {
            this.serviceItem.data.cleanup_time =
              this.serviceItem.data.cleanup_time - 15;
          }
        }
      }
    },
    increaseTime(type) {
      if (type == "setup") {
        this.setup = this.setup + 15;
      } else {
        this.cleanup = this.cleanup + 15;
      }
    },
    submitForm(values) {
      if (values) {
        this.submitCompanyData(values);
      }
    },
    setTranslations() {
      if (this.languages && this.languages.data) {
        let translations = [];
        this.languages.data.forEach(language => {
          let object = {
            locale: language.locale,
            attributes: {
              name: "",
              description: ""
            }
          };
          translations.push(object);
        });
        this.setServiceItemTranslations(translations);
      }
    },
    sortLanguages(a, b) {
      const currentLang = getLang();
      if (a.locale === currentLang && b.locale !== currentLang) {
        return -1;
      }

      if (a.locale !== currentLang && b.locale === currentLang) {
        return 1;
      }

      return 0;
    },
    submitCompanyData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });

      if (this.serviceItemTranslations.length) {
        values.translations = this.serviceItemTranslations;
      }
      delete values.hasQuantity;
      values.has_quantity = this.hasQuantity ? Number(this.hasQuantity) : 0;
      values.service_type_id = this.typeId;
      values.setup_time = this.setup;
      values.cleanup_time = this.cleanup;
      httpServiceAuth
        .post(apiEndpoints.company.serviceItems, values)
        .then(response => {
          this.getServiceItems();
          this.$store.commit("service/setServiceItemTranslations", [], {
            root: true
          });
          this.$store.commit("service/setServiceItemTranslations", [], {
            root: true
          });
          setTimeout(() => {
            if (this.formKey === 10) {
              this.formKey = 0;
            } else {
              this.formKey++;
            }
          }, 0);
          this.$router.push({
            name: "r_edit-service-item",
            params: { service_item_id: response.data.data.id }
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  beforeUnmount() {
    this.$store.commit("service/setServiceItemTranslations", [], {
      root: true
    });
    this.$store.commit("service/setServiceItemTranslations", [], {
      root: true
    });
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
<style>
.change-time {
  border: 1px solid lightgray;
  padding-top: 1px;
  font-size: 18px;
  cursor: pointer;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
